import { Ontology } from "tulkas-lib/primitives";

export const ENVS = Object.freeze({
  dev: "dev",
  prod: "prod",
});

export const DEFAULT_S3_REGION = process.env.REACT_APP_DEFAULT_S3_REGION;
export const USABLE_FILE_TYPES = ["tif", "ln", "geojson", "lngj/"];
export const DEFAULT_INDEX_DIR = "s3://valar-airen/airen0/data/";

export const FINDER_WIDTH = 250;
export const SIGN_OUT_HEIGHT = 220;
export const DIR_PROPORTION = 0.8;
export const LAYER_PROPORTION = 0.2;

export const GLOBAL_ONTOLOGY_DICT = {
  // Global cell and tissue
  tumor: { visible: true, color: "rgba(255, 0, 0, 1)" },  
  tumor_colorectal: { visible: true, color: "rgba(255, 0, 0, 1)" },
  tumor_bladder: { visible: true, color: "rgba(255, 0, 0, 1)" },
  tumor_pancreas: { visible: true, color: "rgba(255, 0, 0, 1)" },
  tumor_prostate: { visible: true, color: "rgba(255, 0, 0, 1)" },
  tumor_breast: { visible: true, color: "rgba(255, 0, 0, 1)" },
  tumor_skin: { visible: true, color: "rgba(255, 0, 0, 1)" },

  stroma: { visible: true, color: "rgba(0, 0, 255, 1)" },
  muscle: { visible: true, color: "rgba(0, 0, 128, 1)" },
  nerve: { visible: true, color: "rgba(0, 0, 255, 1)" },
  fat: { visible: true, color: "rgba(0, 0, 255, 1)" },

  lymphocyte: { visible: true, color: "rgba(106, 168, 79, 1)" },
  endothelial: { visible: true, color: "rgba(0, 255, 255, 1)" },
  liver_endothelial: { visible: true, color: "rgba(0, 0, 255, 1)" },
  eosinophil: { visible: true, markers: false, color: "rgba(153, 0, 255, 1)" },
  neutrophil: {
    visible: true,
    markers: false,
    color: "rgba(255, 255, 255, 1)",
  },
  plasma: { visible: true, markers: false, color: "rgba(255, 255, 0, 1)" },
  macrophage: { visible: true, markers: false, color: "rgba(255, 153, 0, 1)" },
  mitosis: { visible: true, markers: false , color: "rgba(255, 0, 165, 1)"}, // pink 
  apoptosis: { visible: true, markers: false, color:"rgba(170, 110, 40 ,1)" }, // brown
  rbc: { visible: true, markers: false, color: "rgba(128, 0, 0, 1)" },
  none: { visible: true, markers: false, color: "rgba(0, 0, 0, 1)" },

  // Global tissue
  necrosis: { visible: true, color: "rgba(0, 0, 0, 1)" },
  mucin: { visible: true, color: "rgba(204, 204, 0, 1)" },
  benign_mucin: { visible: true, color: "rgba(0, 255, 0, 1)" },

  immune_infiltrate: { visible: true, color: "rgba(255, 255, 255, 1)" },
  tls: { visible: true, color: "rgba(255, 0, 255, 1)" },
  lymph_node: { visible: true, color: "rgba(0, 0, 255, 1)" },

  cis: { visible: true, color: "rgba(132, 178, 133, 1)" },
  islets: { visible: true, color: "rgba(0, 255, 0, 1)" },
  duct: { visible: true, color: "rgba(0, 255, 0, 1)" },
  alveoli: { visible: true, color: "rgba(0, 255, 0, 1)" },
  liver: { visible: true, color: "rgba(0, 255, 0, 1)" },
  bone: { visible: true, color: "rgba(0, 255, 0, 1)" },
  bruners: { visible: true, color: "rgba(0, 128, 0, 1)" },
  
  // bladder specific
  bladder: { visible: true, color: "rgba(0, 255, 0, 1)" },
  
  // prostate specific
  prostate_acini: { visible: true, color: "rgba(0, 255, 0, 1)" },
  
  // pancreas specific
  pancreas_acini: { visible: true, color: "rgba(0, 255, 0, 1)" },
  
  // breast specific
  breast: { visible: true, color: "rgba(0, 255, 0, 1)" },

  //colorectal specific
  intestine: { visible: true, color: "rgba(0, 255, 0, 1)" },
  adenoma: { visible: true, color: "rgba(0, 128, 0, 1)" },

  // skin specific
  skin: { visible: true, color: "rgba(0, 255, 0, 1)" },
  hair_follicle: { visible: true, color: "rgba(0, 255, 0, 1)" },
  
  // Background classes
  background: { visible: true, color: "rgba(0, 0, 0, 1)" },
  mechanical: { visible: true, color: "rgba(0, 0, 0, 1)" },
  blood: { visible: true, color: "rgba(0, 0, 0, 1)" },
  wax: { visible: true, color: "rgba(0, 0, 0, 1)" },
  blurring: { visible: true, color: "rgba(0, 0, 0, 1)" },
  bubble: { visible: true, color: "rgba(0, 0, 0, 1)" },
  folds: { visible: true, color: "rgba(0, 0, 0, 1)" },
  pen: { visible: true, color: "rgba(0, 0, 0, 1)" },

  
  unlabeled: { visible: true, markers: false, color: "rgba(0, 0, 0, 1)" },
  roi:{visible:true, color:"rgba(0,0,0,1)"},

  // legacy
  neoplastic: { visible: true, color: "rgba(255, 0, 0, 1)" },  
  stromal: { visible: true, color: "rgba(0, 0, 255, 1)" },
};

export const GLOBAL_ONTOLOGY = new Ontology(GLOBAL_ONTOLOGY_DICT);

export const INIT_MAP_BOUNDS = [
  [0, 0],
  [5128, 6190],
];
export const TILE_SIZE = 512;

export const SIGN_OUT_DIALOG = {
  dialogTitle: "Are you sure you want to sign out?",
  dialogText:
    "You will be logged out of your account and redirected to the login page.",
};
